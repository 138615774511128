"use strict";

var isMobileVersion = false;
$(document).ready(function () {
  //для смены изображений при наведение на категорию в меню
  if (document.getElementById("menu-category")) {
    //задаем ширину подменю, чтобы не выходило за переделы окна
    setTimeout(function () {
      var mainContent = document.querySelector('.main-content'); //прокрутка к меню категорий если не прокрутили ниже

      var menuCategoryOffsetTop = $('#menu-category').offset().top;

      if (!Visible(mainContent) && $(window).scrollTop() < menuCategoryOffsetTop) {
        $('html, body').animate({
          scrollTop: menuCategoryOffsetTop
        }, 500);
      }
    }, 2500);
    var appMenuCategory = new Vue({
      delimiters: ['${', '}'],
      el: '#menu-category',
      data: {
        isScrollWidth: false,
        productPreview: ''
      },
      methods: {
        setPositionSubMenu: function setPositionSubMenu() {
          $(document).find('.wrap-category-sub:visible').each(function () {
            $(this).removeAttr('style');
            var rightOffset = 0;
            var offset = $(this).offset();
            rightOffset = $(window).width() - offset.left - $(this).width();

            if (rightOffset < 0) {
              $(this).css({
                'left': rightOffset
              });
            }
          });
        },
        setProductPreview: function setProductPreview(product) {
          var productHTML = '';

          if (product.name && product.price && product.images && product.folder) {
            var productImage = '<img  class="category-product-preview__image" src="/images/products/' + product.folder + '/thumb_product/' + product.images.main + '">';
            var productName = '<div class="category-product-preview__name">' + product.name + '</div>';
            var productLinkBegin = '<a href="/shop/products/' + product.url + '">';
            var productLinkEnd = '</a>';
            productHTML = productLinkBegin + productImage + productName + productLinkEnd;
            this.productPreview = productHTML;
          }
        }
      }
    });
  }

  if (document.getElementById("menu-category-sticky")) {
    var appMenuCategorySticky = new Vue({
      delimiters: ['${', '}'],
      el: '#menu-category-sticky',
      data: {
        isScrollWidth: false,
        productPreview: ''
      },
      methods: {
        setPositionSubMenu: function setPositionSubMenu() {
          $(document).find('.wrap-category-sub:visible').each(function () {
            $(this).removeAttr('style');
            var rightOffset = 0;
            var offset = $(this).offset();
            rightOffset = $(window).width() - offset.left - $(this).width();

            if (rightOffset < 0) {
              $(this).css({
                'left': rightOffset
              });
            }
          });
        },
        setProductPreview: function setProductPreview(product) {
          var productHTML = '';

          if (product.name && product.price && product.images && product.folder) {
            var productImage = '<img  class="category-product-preview__image" src="/images/products/' + product.folder + '/thumb_product/' + product.images.main + '">';
            var productName = '<div class="category-product-preview__name">' + product.name + '</div>';
            var productLinkBegin = '<a href="/shop/products/' + product.url + '">';
            var productLinkEnd = '</a>';
            productHTML = productLinkBegin + productImage + productName + productLinkEnd;
            this.productPreview = productHTML;
          }
        }
      }
    });
  } // красивое написание цены


  $('span.price-to-locale').each(function () {
    $(this).text(parseInt($(this).text()).toLocaleString('ru'));
  }); //меню после прокрутки страницы вниз

  if (document.getElementsByClassName("breadcrumbs").length) {
    var offsetBreadcrumb = $('.breadcrumbs').offset().top;
    $(document).scroll(function () {
      if ($(window).scrollTop() > offsetBreadcrumb) {
        if ($('.menu-sticky').is(':hidden')) {
          $('.menu-sticky').css({
            'display': 'flex'
          });
          setTimeout(function () {
            $('.menu-sticky').css({
              'display': 'flex',
              'top': 0
            });
          }, 100);
        }
      }

      if ($(window).scrollTop() < offsetBreadcrumb) {
        if ($('.menu-sticky').is(':visible')) {
          $('.menu-sticky').css({
            'display': 'flex',
            'top': '-100%'
          });
          setTimeout(function () {
            $('.menu-sticky').css({
              'display': 'none'
            });
          }, 500);
        }
      }
    });
  }

  $('.menu-block .nav-item').hover(function () {
    var image = $(this).data('image');

    if (image) {
      var imageContainer = $(this).parents('.menu-block').find('.product-for-display img').attr('src', image);
    }
  });
  getWidgetCartData();
}); // определяет есть ли scroll(var a: может быть Width или Height с заглавных букв)

function get_scroll(a) {
  var d = document,
      b = d.body,
      e = d.documentElement,
      c = "client" + a;
  a = "scroll" + a;
  return /CSS/.test(d.compatMode) ? e[c] < e[a] : b[c] < b[a];
} //подгрузка данных для виджета корзины


function getWidgetCartData() {
  var widgetCart = {
    count: 0,
    sum: 0
  };
  axios.get('/ajax/json/cart-widget').then(function (response) {
    if (response.data) {
      widgetCart = response.data;
    }

    console.log();

    if (widgetCart.sum) {
      $('.cart-menu-sticky').removeClass('empty');
      $('.widget-sum-cart').text('(' + widgetCart.sum.toLocaleString('ru') + ' ₽' + ')'); //$('.widget-count-cart').text(widgetCart.count);
    } else {
      $('.widget-sum-cart').text('');
      $('.widget-count-cart').text();
      $('.cart-menu-sticky').addClass('empty');
    }
  }).catch(function (error) {//console.log(error);
  });
} //определить что элемент в зоне видимости окна браузера?


function Visible(target) {
  var returnVal = false; // Все позиции элемента

  var targetPosition = {
    top: window.pageYOffset + target.getBoundingClientRect().top,
    left: window.pageXOffset + target.getBoundingClientRect().left,
    right: window.pageXOffset + target.getBoundingClientRect().right,
    bottom: window.pageYOffset + target.getBoundingClientRect().bottom
  },
      // Получаем позиции окна
  windowPosition = {
    top: window.pageYOffset,
    left: window.pageXOffset,
    right: window.pageXOffset + document.documentElement.clientWidth,
    bottom: window.pageYOffset + document.documentElement.clientHeight
  };

  if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
  targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
  targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
  targetPosition.left < windowPosition.right) {
    // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
    // Если элемент полностью видно, то запускаем следующий код
    returnVal = true;
  }

  return returnVal;
}
